import { graphql } from 'gatsby'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import tw, { css } from 'twin.macro'
import Button from '../../components/button'
import Layout from '../../components/layout'
import { flatten } from '../../utils/data'

const NotFoundPage = ({ data: { pageNotFound }, location }) => {
  const page = useMemo(
    () =>
      flatten(pageNotFound || {}, [
        'pageHeader',
        'pageSubtitle',
        'pageMainContent',
        'pageLink',
      ]),
    [pageNotFound]
  )

  const pageContent = (
    <h1
      css={tw`inline-block text-primary-500 font-bold whitespace-no-wrap text-3xl leading-normal px-8 md:(text-5xl px-32) lg:text-8xl`}
    >
      {page.pageMainContent}
    </h1>
  )

  return (
    <Layout pageTags={page.seoMetaTags} noIndex={page.noIndex} location={location}>
      <section css={tw`h-screen overflow-hidden flex items-center`}>
        <div css={tw`flex flex-col items-start first-of-type:pt-0 h-1/2 max-w-full w-8/10 mx-auto md:h-3/4`}>
          <div css={tw`w-full`}>
            <h6 css={tw`text-primary-500 font-bold text-xl`}>
              {page.pageHeader}
            </h6>
          </div>
          <div
            css={css`
              width: fit-content;
              @keyframes scroll {
                0% {
                  transform: translateX(calc(-25%));
                }
                100% {
                  transform: translateX(calc(-50%));
                }
              }
              &:hover {
                animation-play-state: paused;
                }
              }
              animation: scroll 12s linear infinite;
              ${tw`relative flex -translate-x-40 my-15/10`}
            `}
          >
            {pageContent}
            {pageContent}
            {pageContent}
            {pageContent}
          </div>
          <div css={tw`w-full`}>
            <div css={tw`mt-8`}>
              <p css={tw`whitespace-pre-wrap`}>{page.pageSubtitle}</p>
              <div css={tw`flex mt-16`}>
                <Button
                  label={get(page, 'pageLink.label')}
                  link={get(page, 'pageLink.link')}
                  type="primary"
                  size="xs"
                  theme="navy-navyopaque"
                  hideCaret
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    pageNotFound: PropTypes.object.isRequired,
  }),
  location: PropTypes.object,
}

export default NotFoundPage

export const query = graphql`
  query NotFoundQuery($locale: String!) {
    pageNotFound: datoCmsSharedPageNotFound(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      pageHeader
      pageSubtitle
      pageMainContent
      pageLink  {
        label
        link
      }
    }
  }
`
